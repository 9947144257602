.portfolio-area{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-right: 5%;
    
}


@media (min-width: 900px) {
    
    .portfolio-title{
        font-size: 3rem;
        color: #5D3FD3;
        
        
    }

    .box-area{
        margin-top: 5% ;
       display: grid;
       grid-gap: 25px;
       grid-template-columns: repeat(3, auto);
       grid-template-rows: repeat(2, auto);
    }
    .box{
        
        width: 400px;
        height: 300px;
        margin-right: 5%;
        border: 3.5px solid  #5D3FD3;
        transition: 0.3s all ease-in-out ;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .project-text {
        margin-top: 2%;
        width: 90%;
        overflow-wrap: break-word;
        font-size: 1.2rem;
        margin-left: 2%;
        
    }
    
    .project-title{
        margin-top: 10%;
        margin-left: 2%;
        font-size: 1.5rem;
        
    }

    .box:hover{
        transform: scale(1.05);
        cursor: pointer;
        
    }

    .box:hover .project-title{
        color: #5D3FD3;
    }

    .box-icons{
        margin-top: 5%;
        display: flex;
        flex-direction: row;
    }

    .portfolioGithub-icon{
        margin-left: 200px;
        
        margin-right: 5%;
    }

    .portfolioGithub-icon, .redirect-icon{
        margin-top: 10px;
        height: 30px;
        width: 30px;
        margin-right: 10px;
    }
    
    .folder-icon{
        height: 50px;
        width: 100px;
    }

    .technologies{
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        margin-left: 10px;
        font-size: 0.9rem;
    }

    .repo-button{
        width: 150px;
        height: 70px;
        border: 3px solid #5D3FD3;
        color: #5D3FD3;
        font-size: 1rem;
        background-color: #fff;
        transition: 0.175s all ease-out;
        border-radius: 3%;
    }

    .repo-button:hover{
        background-color: #CBC3E3;
        cursor: pointer;
    }

    .portfolioGithub-icon:hover, .redirect-icon:hover, .folder-icon:hover{
        color: #5D3FD3;
    }
    .repo-link{
        margin-top: 2%;
    }
    
}
