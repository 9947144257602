
.socials-area{
    border-top: 2px solid #5D3FD3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    
    
}

 .github-icon, .linkedin-icon{
    height: 20px;
    width: 20px;
    margin-bottom: 10px;
    margin-left: 30px;
    margin-top: 5px;
}

.instagram-icon{
    height: 20px;
    width: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 5px;
}

@media (min-width: 900px) {
    .socials-area{
        display: flex;
        flex-direction: column;
        
        position: fixed;
        left: 0;
        bottom: 0;
        border: 0;
        width: 5%;
    }

    .instagram-icon, .github-icon, .linkedin-icon{
        height: 20px;
        width: 20px;
        margin-bottom: 10px;
        margin-left: 7%;
    }

    .instagram-icon:hover, .github-icon:hover, .linkedin-icon:hover{
        color: #5D3FD3;
    }

    .socials-line{
        height: 100px;
        border: none;
        background-color: purple;
        width: 2px;
        margin-left: 0;
    }
}