.subtitle{
    opacity: 0.7;
    font-weight: normal;
    font-size: 1.2rem;
    
}

.title{
    white-space: nowrap;
    font-size: 2rem;
    left: 0;
    margin-top: 0;
    color: #5D3FD3;
    
}

@media (min-width:933px ) {
    .title{
        font-size: 4.5rem;
    }
}

.text{
    margin-top: 1%;
    font-size: 1rem;
    
    
    
}

@media (min-width: 600px) {
    .text{
        
        font-size: 1.7rem;
    
        
    }

}



.download-link{
    margin-top: 2%;
    
}

.resume-download{
    height: 2em;
    width: 8em;
    border: 4px solid #5D3FD3;
    background-color: #fff;
    color: #5D3FD3;
    border-radius: 4%;
    font-size: 0.9rem;
    box-shadow:
		0 1px 2px rgba(0,0,0,0.07), 
		0 2px 4px rgba(0,0,0,0.07), 
		0 4px 8px rgba(0,0,0,0.07), 
		0 8px 16px rgba(0,0,0,0.07),
		0 16px 32px rgba(0,0,0,0.07), 
		0 32px 64px rgba(0,0,0,0.07);
    cursor: pointer;
    transition: 0.4s ease;
}

@media (min-width: 500px) {
    .resume-download{
        height: 5em;
        width: 12em;
        font-size: 1.15rem;
    }
    .download-icon{
        height: 1.3em;
    }
}

.resume-download:hover{
    background-color: purple;
    border: 4px solid purple;
    color: #fff;
}

.download-icon{
    margin-left: 5px;
    height: 0.9em;
}

