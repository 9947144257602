.contact-area{
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 4%;
    margin-bottom: 10%;
}

.contact-textArea{
    margin-left: 4%;
    width: 70%;
    margin-top: 2%;
    margin-bottom: 2%;
}
.contact-text{
   
    text-align: center;
    overflow-wrap: break-word;
    
}

.contact-title{
    font-size: 3rem;
    color: #5D3FD3;
}

.contact-button{
    width: 150px;
    height: 50px;
    
    border: 2px solid #5D3FD3;
    font-size: 1rem;
    margin-left: 2%;
    background-color: #fff;
    color: #5D3FD3;
    transition: 0.175s all ease-out;
    border-radius: 3%;
    margin-top: 2%;
}

@media (min-width: 900px) {
    .contact-area{
        margin-top: 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 5%;
        margin-bottom: 10%;
        
    }
    .contact-title{
        font-size: 3rem;
        color: #5D3FD3;
    }

    .contact-textArea{
        width: 50%;
        overflow-wrap: break-word;
    }
    .contact-text{
        
        width: 100%;
        height: 100%;
        text-align: center;
        margin-top: 3%;
        font-size: 1.5rem;
        overflow-wrap: break-word;
        
    }

    .contact-button{
        width: 250px;
        height: 70px;
        
        border: 3px solid #5D3FD3;
        font-size: 1.2rem;
        margin-left: 2%;
        background-color: #fff;
        color: #5D3FD3;
        transition: 0.175s all ease-out;
        border-radius: 3%;
    }
    .contact-button:hover{
        background-color: #CBC3E3;
        cursor: pointer;
    }

    .email{
        height: 70px;
        margin-top: 5%;
        
    }

    
}