.sidebar-area{
    position: fixed;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 99;
    width: 80%;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: 0.35s all ease-out;
}

.sidebar-links{
    display: flex;
    flex-direction: column;
     
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    
    position: relative;
    
}

.sidebar-about, .sidebar-skills, .sidebar-work, .sidebar-contact{
    margin-bottom: 30px;
}

.exit{
    position: absolute;
    top: 60px;
    left: 200px;
    background-color: #fff;
    border: none;
    height: 40px;
    width: 40px;
}

.exit-icon{
    height: 100%;
    width: 100%;
    color: #5D3FD3;
}

.sidebar-cv{
    border: 2px solid #5D3FD3;
    background-color: #fff;
    height: 3em;
    width: 10em;
    color: #5D3FD3;
    font-size: 1.3rem;
}