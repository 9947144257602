.about-title{
    font-size: 3rem;
    display: flex;
    flex-direction: row;
}

.about-titleText{
    margin-left: 0.7%;
    color: #5D3FD3;
}

.linha{
    width: 100%;
    background-color: #5D3FD3;
    border: none;
    height: 2px;
}

.about-text {
    
    margin-top: 2%;
    width: 90%;
    margin-bottom: 3%;
    font-size: 1.2rem;
    margin-right: 15px;
    word-wrap: break-word;
    margin-left: 2%;
}

@media (min-width: 600px) {
    .about-text {
        width: 50%;
        
        font-size: 1.2rem;
        margin-right: 15px;
        word-wrap: break-word;
    }

    .about-text p{
        margin-top: 5%;
    }

    .linha{
        height: 0.3vh;
        background-color: #5D3FD3;
        border: none;
        width: 60%;
    }

    .image-area{
        width: 50%;
        right: 0;
        position: absolute;
        right: 0;
        top: 70px;
        height: 70%;
    }
}