
.Responsiveportfolio-area{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30%;
    height: 300px;
}

.Responsiveportfolio-title{
    font-size: 2.5rem;
    color: #5D3FD3;
}

.Responsivebox-area{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Responsivebox{
    margin-top: 5%;
    width: 70%;
    border: 3.5px solid #5D3FD3;
    margin-left: 6%;
    margin-right: 4%;
    height: 200px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 5%;
    transition: 0.5s all ease;
}

.Responsiveproject-text{
    width: 90%;
    margin-left: 3%;
    overflow-wrap: break-word;
    margin-top: 3%;
    margin-bottom: 2%;
    transition: 0.5s all ease;

}

.Responsiveproject-title{
    margin-top: 2%;
    margin-left: 2%;
    font-size: 1.2rem;
}

.Responsivebox-icons{
    display:  flex;
    flex-direction: row;

}

.Responsivefolder-icon{
    height: 50px;
    margin-left: 15px;
    margin-top: 5px;
    
}

.Responsivegithub-icon{
    margin-left: 100px;
}

.Responsiveredirect-icon{
    margin-left: 20px;
    margin-right: 10px;
}

.Responsivegithub-icon, .Responsiveredirect-icon{
    margin-top: 12px;
    height: 30px;
    width: 30px;
}

.left-arrow, .right-arrow{
    height: 50px;
    width: 80px;
    background-color: #fff;
    color: #5D3FD3;
    border: none;
}

.arrow{
    height: 100%;
    width: 100%;
}
.left-arrow{
    margin-left: 4px;
}

.right-arrow{
    margin-right: 4px;
}

.Responsivetechnologies{
    display: flex;
    flex-direction: row;
    margin-left: 1%;
}

.Responsiverepo-button{
    width: 150px;
    height: 50px;
    border: 2px solid #5D3FD3;
    font-size: 1.10rem;
    color: #5D3FD3;
    background-color: #fff;
}