@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');

html{
    scroll-behavior: smooth;
    
}

body{
    
    overflow-x: hidden;
}

*{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.App{
    display: flex;
    flex-direction: column;
    
}



.Header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 5px solid #5D3FD3;
    padding-bottom: 10px;
    padding-top: 30px;
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100vw;
    position: fixed;
    background-color: #fff;
    z-index: 99;
    
}

.nav{
   display: flex;
   flex-direction: row;
   justify-content: center;
}

.nav-about, .skills-nav, .work-nav, .contact-nav{
    margin-right: 30px;
    font-size: 23px;
    text-decoration: none;

}
.nav-about:hover, .skills-nav:hover, .work-nav:hover, .contact-nav:hover{
    color: #5D3FD3;
}

.contact-nav{
    margin-right: 100px;
}

.intro{
    margin-top: 5%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 25%;
    width: 40%;
    height: 60vh;
    
}

@media (max-width: 390px) {
    .Header{
        width: 390px;
    }
    .intro{
        margin-left: 5px;
        margin-top: 5%;
        width: 95%;
        margin-right: 15px;
        height: 100vh;
    }
}

.about{
    margin-top: 15%;
    position: relative;
    margin-bottom: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    margin-left: 15%;
    
    
}

@media (min-width: 900px) {
    .about{
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;
        margin-left: 30%;
        margin-bottom: 5%;
    }
}

.logo-responsive{
    width: 50px;
    height: 50px;
}

.Header-responsive{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 5px solid #5D3FD3;
    padding-bottom: 10px;
    padding-top: 30px;
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100vw;
    position: fixed;
    background-color: #fff;
    z-index: 99;
    top: 0;
}

.sidebar-button{
    background-color: #fff;
    border: none;
    margin-right: 30px;
    height: 60px;
    width: 60px;
}

.sidebar-buttonIcon{
    color: #5D3FD3;
    height: 60%;
    width: 100%;
}