.skills-display{
    margin-top: 40%;
    margin-left: 15%;
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10%;
}

.skills-line{
    display: flex;
    flex-direction: row;
    margin-top: 6%;
}

.skills-line a{
    text-decoration: none;
    background-color: transparent;
}

.skills-title{
    font-size: 3rem;
    margin-left: 5%;
    color: #5D3FD3;
}

.icon-skill{
    height: 4em;
    margin-left: 8px;
    width: 4em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #5D3FD3;
    transition: 0.2s all ease-in-out;
    
}

.skill-brand{
    height: 100%;
    width: 100%;
}

.skill-text{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

@media (min-width: 900px) {
    .skills-display{
        margin-left: 10%;
        width: 70vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5%;
        
    }

    .skills-line{
        display: flex;
        flex-direction: row;
        margin-top: 6%;
    }

    .skills-line a{
        text-decoration: none;
        background-color: transparent;
    }
    .skills-title{
        font-size: 3rem;
        margin-left: 5%;
        color: #5D3FD3;
    }
    .icon-skill{
        height: 5em;
        margin-left: 100px;
        width: 5em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: #5D3FD3;
        transition: 0.2s all ease-in-out;
        
    }

    .icon-skill:hover{
        transform: scale(1.5);
        cursor: pointer;
    }

    .skill-brand{
        height: 100%;
        width: 100%;
    }
    .skill-text{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }
}